:root {
  --toolbar-height: 56px;
  --colorPrimary: #23272a;
  --colorToolbar: #23272a;
  --colorAccent: #2185d0;
  --colorSidebar: #23272a;
  --colorRed: #b03060;
  --colorOrange: #fe9a76;
  --colorYellow: #ffd700;
  --colorOlive: #32cd32;
  --colorGreen: #016936;
  --colorTeal: #008080;
  --colorBlue: #0e6eb8;
  --colorViolet: #ee82ee;
  --colorPurple: #b413ec;
  --colorPink: #ff1493;
  --colorBrown: #a52a2a;
  --colorGrey: #a0a0a0;
  --colorBlack: #000000;
}

@font-face {
  font-family: special;
  src: url('/fonts/SourceSansPro-BoldIt.otf');
}

body,
html {
  height: unset;
}

.ui.page.modals.transition.visible {
  display: flex !important;
}

.pointer {
  cursor: pointer;
}

.background-black {
  background-color: black;
}

.background-white {
  background-color: white;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.black {
  color: black;
}

.white {
  color: white !important;
}

.color-colorWhite {
  color: white !important;
}

.lato {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

textarea {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

.image-gallery-image img {
  height: 500px;
  object-fit: contain;
  background-color: black;
}

.modal-fix {
  margin-top: 0 !important;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 40rem) {
  .image-gallery-image img {
    height: 200px;
    object-fit: contain;
    background-color: black;
  }
}

.footer {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-picture {
  min-height: 34px;
  min-width: 34px;
  height: 34px;
  width: 34px;
  object-fit: cover;
}

.profile-picture-toolbar {
  height: 20px;
  width: 20px;
  object-fit: cover;
}

.profile-picture-large {
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.text-dim {
  color: rgba(0, 0, 0, 0.4);
}

.text-small {
  font-size: 0.875em;
}

.below-toolbar {
  margin-top: var(--toolbar-height);
}

.main-content {
  min-height: calc(100vh - var(--toolbar-height));
  margin-top: var(--toolbar-height);
  white-space: pre-line;
}

.card-item {
  /* Add shadows to create the "card" effect */
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 12px;
}

/* On mouse-over, add a deeper shadow */
.card-hover:hover {
  box-shadow: 1px 1px 6px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.hover-gray:hover {
  background-color: #efefef;
}

.hover-lighter:hover {
  filter: brightness(115%);
}

.hover-darker:hover {
  filter: brightness(85%);
}

.faster {
  -webkit-animation-duration: 250ms !important;
  animation-duration: 250ms !important;
}

.selected-card {
  background-color: #eeeeee !important;
}

.toolbar {
  height: var(--toolbar-height);
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

.toolbar-shadow {
  position: absolute;
  height: 5px;
  width: 100%;
  color: white;
  top: var(--toolbar-height);
  left: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.3+0,0+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */
}

.toolbar-item {
  height: 100%;
}

.toolbar-item-square {
  width: var(--toolbar-height);
}

.flex-grow {
  flex-grow: 1;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.touch-action-none {
  touch-action: none;
}

.toolbar-logo {
  max-height: 46px;
  max-width: 80px;
}

.google {
  background-color: #dc4e41 !important;
}

.facebook {
  background-color: #3b5998 !important;
}

.h-100 {
  height: 100%;
}

.noselect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.post-image {
  width: 100px;
  height: 100px;
}

.comment {
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-top-left-radius: 0.28571429rem;
  margin-top: 6px;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 6px;
}

.comment:hover {
  border-left: 1px solid rgba(34, 36, 38, 0.5);
  border-top: 1px solid rgba(34, 36, 38, 0.5);
}

.translucent {
  opacity: 0.5;
}

.markdown-container {
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  content: '';
  position: relative;
}

.markdown-container:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 50px, white);
}

.small-icon {
  font-size: 14pt;
}

.hover-underline:hover {
  text-decoration: underline;
}

.tos-bar {
  width: 100%;
  padding: 8px;
  background-color: #ddd;
}

.anchor {
  color: #4183c4;
  cursor: pointer;
}

.anchor:hover {
  text-decoration: underline;
}

.profile-badge-container {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  padding: 1px 8px;
  border-radius: 4px;
}

.profile-badge-mastermind {
  margin-left: 4px;
}

.posted-by-push-right {
  margin-right: .20em;
}
